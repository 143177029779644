.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pinned-column-cell {
  position: sticky;
  left: 40px;
  background-color: white;
  z-index: 1;
}

.table-head{
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.table-head th{
  border: 1px solid #676767;
}

.results-head{
  background-color: #d5d5d5;
  position: sticky;
  left: 0;
}

.table-input{
  width: 100%;
  min-height: 120px;
  text-align: center;
  resize: none;
  border: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

.table-main td{
  min-height: 120px;
  text-align: center;
  border: 1px solid #676767;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 0.8rem;
}

.main-toogle-button{
  color: white !important;
}

.main-toogle-button.Mui-selected{
  background-color: #4caf50 !important;
  color: white !important;
}

.global-search{
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 2;
  height: 48px;
  background-color: #f8f9fa;
}

.table-domain-number{
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
  min-width: 40px;
}

.sticky-column {
  position: sticky;
}

.relative-column {
  position: relative;
}

@media (max-width: 768px) {
  .sticky-column {
    position: relative;
    left: 0px !important;
  }
}

.table-history td{
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 0.8rem;
  max-width: 200px;
}

.loading-cell{
  height: 90px;
}

.editing{
  background-color: #b9b9b9;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
}